import React from 'react';

const HomeSvg = (props: any) => (
  <svg
    height={30}
    viewBox="0 0 450.818 450.818"
    width={30}
    fill="none"
    {...props}
  >
    <path d="m445.538 172.134-51.07-40.856v-89.02c0-7.787-6.301-14.088-14.088-14.088h-56.325c-7.773 0-14.088 6.301-14.088 14.074l-.014 21.421-75.738-60.59c-5.146-4.1-12.465-4.1-17.61 0l-211.324 169.06c-4.664 3.742-6.466 10.03-4.485 15.67 1.981 5.641 7.306 9.424 13.29 9.424h42.264v239.5c0 7.787 6.301 14.088 14.088 14.088h309.942c7.787 0 14.088-6.301 14.088-14.088v-239.5h42.265c5.985 0 11.309-3.783 13.29-9.424s.179-11.928-4.485-15.671zm-107.423-87.597.014-28.19h28.163v52.391l-30.252-24.202c-.001-.001 2.075.001 2.075.001zm-140.882 338.103v-112.705h56.353v112.705zm183.147-253.588c-7.787 0-14.088 6.301-14.088 14.088v239.5h-84.529v-126.794c0-7.787-6.301-14.088-14.088-14.088h-84.529c-7.787 0-14.088 6.301-14.088 14.088v126.794h-84.531v-239.5c0-7.787-6.301-14.088-14.088-14.088h-16.194l171.164-136.933 171.164 136.934c0-.001-16.193-.001-16.193-.001z" />
  </svg>
);

export default HomeSvg;
