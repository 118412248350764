import React, { useState } from 'react';
import { useAsync, UseAsyncReturn } from 'react-async-hook';

import { getMatterports } from '../../appAPI';
import { delayPromise } from '@shoootin/utils';
import { GroupedMatterport } from '../../appAPITypes';
import { H1, H2 } from '../../primitives/appTitle';
import { usePageParams } from '../../layout/appContext';
import { isDev } from 'appEnv';
import { AppSEOTitle } from '../../appSEO';
import { MatterportLoading } from './scanPage';
import { MainLayout } from '../../layout/mainLayout';
import PageError, { PageErrorContent } from '../pageError';
import { AppInternalLink, AppInternalPaths } from '../../primitives/appLink';
import LogoSvg from '../../components/svg/logoSvg';
import { ShText } from '@shoootin/translations';
import { FrontCommonMessages } from '../../appCommonTranslations';
import PlaySvg from '../../components/svg/icon/playSvg';
import HomeSvg from '../../components/svg/icon/homeSvg';
import { ShSingleMatterport } from '@shoootin/components-web';
import { ShColors, ShFonts } from '@shoootin/design-tokens';

const isObjectIdValid = (objectId: string) => {
  return objectId.match(/^[0-9a-fA-F]{24}$/);
};

const GroupedMatterportsLayout = ({
  groupedMatterports,
}: {
  groupedMatterports: GroupedMatterport;
}) => {
  const firstMatterport = groupedMatterports.matterports[0];
  const [visible, setVisible] = useState<string | undefined>(undefined);
  const [backgroundImg, setBackgroundImg] = useState<string>(
    firstMatterport.matterportImage,
  );

  return (
    <div>
      <AppSEOTitle title={`Matterport ${groupedMatterports.title}`} />

      <div>
        {!visible && (
          <div
            css={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundColor: 'black',
              zIndex: 900,
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              textAlign: 'center',
            }}
          >
            <div
              css={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                width: '100%',
              }}
            >
              <div
                css={{
                  padding: 5,
                  width: 'auto',
                  margin: 10,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {groupedMatterports.clientLogoUrl && (
                  <img
                    src={groupedMatterports.clientLogoUrl}
                    css={{
                      maxHeight: 70,
                      maxWidth: '30%',
                    }}
                  />
                )}

                <H1 css={{ fontSize: 25 }}>{firstMatterport.title}</H1>
                <div
                  css={{
                    fontSize: 17,
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ShText
                    message={FrontCommonMessages.multiMatterport}
                    values={{
                      icon: (
                        <PlaySvg
                          height={17}
                          width={17}
                          css={{
                            fill: 'white',
                            marginLeft: 5,
                          }}
                        />
                      ),
                    }}
                  />
                </div>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {groupedMatterports.matterports.map((each) => (
                    <div
                      key={each.matterportId}
                      onMouseOver={(e) =>
                        setBackgroundImg(each.matterportImage)
                      }
                      css={{
                        margin: 8,
                      }}
                    >
                      <H2
                        css={{
                          fontSize: 16,
                          padding: 5,
                          marginBottom: 10,
                        }}
                      >
                        {each.subtitle}
                      </H2>
                      <div
                        css={{
                          height: 25,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {each.isPublic ? (
                          <a
                            css={{ display: 'block', cursor: 'pointer' }}
                            onClick={() => setVisible(each.matterportId)}
                          >
                            <PlaySvg
                              height={25}
                              css={{
                                fill: 'white',
                                cursor: 'pointer',
                              }}
                            />
                          </a>
                        ) : (
                          <small
                            css={{
                              fontFamily: ShFonts.secondary,
                              color: ShColors.error,
                              height: 25,
                              display: 'block',
                            }}
                          >
                            <ShText
                              message={
                                'client_matterport_billing_matterportInactive'
                              }
                            />
                          </small>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <AppInternalLink
                  css={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                  to={AppInternalPaths.service('matterport')}
                >
                  <LogoSvg
                    width={100}
                    height={30}
                    css={{
                      fill: 'white',
                      cursor: 'pointer',
                    }}
                  />
                </AppInternalLink>
              </div>
            </div>
          </div>
        )}
        <>
          {groupedMatterports.clientLogoUrl && (
            <img
              src={groupedMatterports.clientLogoUrl}
              css={{
                position: 'absolute',
                display: visible ? 'block' : 'none',
                right: 20,
                top: 20,
                zIndex: 10,
                maxHeight: 70,
                maxWidth: '20%',
              }}
            />
          )}
          {groupedMatterports.matterports
            .filter((each) => each.matterportId === visible)
            .map((each) => (
              <ShSingleMatterport
                key={each.id}
                matterport={each}
                withShoootinLogo={false}
                isVisible={true}
              />
            ))}

          <div
            css={{
              position: 'absolute',
              bottom: 5,
              left: 0,
              right: 0,
              width: '30%',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <a onClick={(e) => setVisible(undefined)}>
              <HomeSvg
                height={15}
                css={{
                  fill: 'white',
                  cursor: 'pointer',
                }}
              />
              <span
                css={{
                  color: 'white',
                  cursor: 'pointer',
                  fontFamily: 'League Spartan',
                  padding: 5,
                }}
              >
                Home
              </span>
            </a>
          </div>
        </>
      </div>
    </div>
  );
};

const ScansPage = () => {
  const { shootingId } = usePageParams();
  if (!shootingId) {
    return <PageError />;
  }

  const asyncMatterport: UseAsyncReturn<GroupedMatterport> = useAsync(async () => {
    if (isObjectIdValid(shootingId)) {
      if (isDev) {
        await delayPromise(100); // TODO remove
      }
      return getMatterports({ id: shootingId });
    } else {
      throw new Error('Invalid url');
    }
  }, [shootingId]);

  return (
    <div>
      {asyncMatterport.loading && <MatterportLoading />}
      {asyncMatterport.error && (
        <>
          <MainLayout className="light" showContact={false} pageName="error">
            <PageErrorContent message={asyncMatterport.error.message} />
          </MainLayout>
        </>
      )}
      {asyncMatterport.result && (
        <GroupedMatterportsLayout groupedMatterports={asyncMatterport.result} />
      )}
    </div>
  );
};

export default ScansPage;
